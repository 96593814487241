<template>
  <div class="container-fluid user-wrapper">
    <div class="user-infos">
      <h3 class="user-infos-name">
        {{ $t("COMMON.MY_ORGANIZATION") }}
      </h3>
      <div class="user-infos-button">
        <base-button
          v-if="
            !!organization &&
            $currentUserCan($permissions.PERM_EDIT_OWN_ORGANIZATIONS)
          "
          icon
          size="sm"
          @click="editOrganization()"
        >
          <span class="btn-inner--icon">
            <i class="fal fa-edit"></i>
          </span>
        </base-button>
      </div>
    </div>
    <div class="elite-tabs-wrapper">
      <tabs
        fill
        class="flex-column flex-md-row"
        tabNavWrapperClasses="nav-wrapper"
        tabNavClasses="nav elite-tabs"
        value="global"
      >
        <tab-pane title="global" id="1" :active="true">
          <span slot="title">
            <i class="ni ni-cloud-upload-96"></i>
            {{ $t("COMMON.GLOBAL") }}
          </span>
          <organization-view-global
            :organization="organization"
            v-if="organization"
          />
        </tab-pane>

        <tab-pane title="options" id="2" :active="false">
          <span slot="title">
            <i class="fas fa-cogs"></i>
            {{ $t("COMMON.OPTIONS") }}
          </span>
          <div class="">
            <option-list-table
              :filter-organization="organization?.id"
              v-if="organization?.id"
            />
          </div>
        </tab-pane>

        <!-- <tab-pane title="security" id="3" :active="false">
          <span slot="title">
            <i class="ni ni-settings"></i>
            {{ $t("USERS.SECURITY") }}
          </span>
          <user-security :user="user" @onUserUpdated="get" />
        </tab-pane> -->
      </tabs>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import { Tabs, TabPane } from "@/components";
import defaultUser from "@/views/Pages/AdminModule/UserManagement/defaultUser";
import requestErrorMixin from "@/mixins/request-error-mixin";
import OrganizationViewGlobal from "@/views/Pages/AdminModule/OrganizationManagement/partials/OrganizationViewGlobal.vue";
import { mapGetters } from "vuex";
import OptionListTable from "@/views/Pages/AdminModule/OptionManagement/partials/OptionListTable.vue";
import swal from "sweetalert2";

export default {
  layout: "DashboardLayout",

  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    Tabs,
    TabPane,
    OrganizationViewGlobal,
    OptionListTable,
  },

  mixins: [requestErrorMixin],

  data() {
    return {
      user: cloneDeep(defaultUser),
      organization: null,
    };
  },

  computed: {
    ...mapGetters({ me: "profile/me" }),
  },

  watch: {},

  async created() {
    swal.showLoading();
    await this.$store.dispatch("profile/me");
    await this.get();
  },

  methods: {
    async get() {
      try {
        if (!this.$idExist(this.me.organization?.id)) {
          this.$notify({
            type: "danger",
            message: this.$t("ERRORS.NO_ORGANIZATION"),
          });
          this.$router.replace("/");
          return;
        }

        await this.$store.dispatch(
          "organizations/get",
          this.me.organization.id
        );

        this.organization = this.$store.getters["organizations/organization"];
        swal.close();
      } catch (error) {
        swal.close();
        this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async editOrganization() {
      this.$router.push({
        name: "Edit Organization",
      });
    },
  },
};
</script>
